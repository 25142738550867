import styled, {
  Color,
  ColorStep,
  keyframes,
  OpacityHex,
} from 'styled-components';

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

interface SkeletonProps {
  /**
   * occupy the full height of the container
   * @default false
   */
  fullHeight?: boolean;
  /**
   * Background color of the skeleton.
   * @default neutral
   */
  backgroundColor?: Color;
  /**
   * Background color variant. ex 100, 200, 300, 400, 500, 600, 700, 800, 900
   * @default primary
   */
  backgroundColorStep?: ColorStep;
  /**
   * Opacity of the background.
   * @default 100
   */
  backgroundOpacity?: OpacityHex;
}

const Skeleton = styled.div<SkeletonProps>`
  height: ${({ theme, fullHeight }) =>
    fullHeight ? '100%' : theme.spacing[4]};
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing[1]};
  background-color: ${({
    theme,
    backgroundColor = 'neutral',
    backgroundColorStep = '100',
    backgroundOpacity = '100',
  }) =>
    theme.colors[backgroundColor][backgroundColorStep] +
    theme.opacityHex[backgroundOpacity]};
  animation: ${pulse} 2s infinite;
`;

export default Skeleton;
