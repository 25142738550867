import React from 'react';
import { useIntl } from 'react-intl';

import * as S from './DepositModal.styles';

import { Header, Label } from '@ui';
import usePageTrack from '@src/hooks/usePageTrack';
import useSettings from '@src/hooks/useSettings';
import useWallet from '@src/hooks/useWallet';
import { Box, Grid, Spacer } from '@shared/layouts';

export function DepositModal() {
  usePageTrack('deposit_modal', 'core');
  const { stpClabe } = useWallet();
  const { business } = useSettings();
  const { formatMessage } = useIntl();

  if (!business || !stpClabe) {
    return null;
  }

  return (
    <Box width="128" maxWidth="90vw">
      <Header as="h5">
        {formatMessage({
          defaultMessage: 'Para transferir fondos utiliza los siguientes datos',
          id: 'P5s9Fg',
        })}
      </Header>
      <Spacer margin="5" />
      <Grid container>
        <Grid item xs={6}>
          <Label size="lg" strong>
            {formatMessage({
              defaultMessage: 'CLABE:',
              id: '2X1Nna',
            })}
          </Label>
        </Grid>
        <Grid item xs={6}>
          <p>{stpClabe}</p>
        </Grid>
        <Grid item xs={6}>
          <Label size="lg" strong>
            {formatMessage({
              defaultMessage: 'Banco:',
              id: 'W9QYB9',
            })}
          </Label>
        </Grid>
        <Grid item xs={6}>
          <p>STP</p>
        </Grid>
        <Grid item xs={6}>
          <Label size="lg" strong>
            {formatMessage({
              defaultMessage: 'Nombre:',
              id: 'biswPx',
            })}
          </Label>
        </Grid>
        <Grid item xs={6}>
          <p>{business?.taxPayerInfos?.[0]?.taxpayerName}</p>
        </Grid>
      </Grid>
      <Spacer margin="6" />
      <S.TransferInfo>
        {formatMessage({
          defaultMessage:
            'Contáctanos para cualquier duda o problema que tengas.',
          id: '04pwGR',
        })}
      </S.TransferInfo>
    </Box>
  );
}

DepositModal.displayName = 'DepositModal';

export default DepositModal;
