import { gql } from '@apollo/client';

import { SupplierRelationship } from '@src/types/models';

const SUPPLIER_BUSINESS_RELATIONSHIPS_ATTRIBUTES = gql`
  fragment SupplierBusinessRelationshipAttributes on BusinessRelationship {
    id
    active
    providerAlias
    bankingInfos {
      recipient
      bankName
      bankId
      clabe
      id
      validationStatus
      alias
      visibility
    }
    status
    providerId
    providerName
    providerContact {
      id
      email
    }
    supplier {
      id
      name
      hashId
      defaultTaxPayerInfo {
        taxpayerIdentifier
      }
    }
    providerContacts {
      id
      defaultContact
      visibility
      contactable {
        email
        firstName
        lastName
        id
      }
    }
    paymentTerms
    providerImageUrl
    providerImageShortName
    providerImageColorCode
    providerHashId
    pendingTotal
    pendingExpiredTotal
  }
`;

export type SupplierBusinessRelationshipAttributes = Pick<
  SupplierRelationship,
  | 'id'
  | 'providerAlias'
  | 'active'
  | 'bankingInfos'
  | 'status'
  | 'providerId'
  | 'providerName'
  | 'providerContact'
  | 'supplier'
  | 'providerContacts'
  | 'paymentTerms'
  | 'providerImageUrl'
  | 'providerImageShortName'
  | 'providerImageColorCode'
  | 'providerHashId'
  | 'pendingTotal'
  | 'pendingExpiredTotal'
>;

export default SUPPLIER_BUSINESS_RELATIONSHIPS_ATTRIBUTES;
